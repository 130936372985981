import React, { useEffect } from "react";
import Footer from "./Footer/Footer";
import { Space } from "antd";

const Privacy = () => {
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <>
      <div
        className="container-fluid"
        style={{ marginTop: "150px", background: "white" }}
      >
        <div className="container">
          <section className="">
            Effective: November 1, 2020
            <div className="bx--row">
              <p className="">(Subject to Revision)</p>
            </div>
            <div className="bx--row">
              <p className="">
                This Privacy and Security Policy (this “Privacy Policy”)
                explains how we collect, protect, use, and disclose the Private
                Information (defined below) when you use our website located at{" "}
                <a href="https://deepecom.com/" className="">
                  https://deepecom.com/
                </a>{" "}
                or any other website we operate (collectively the “Sites”),
                including without limitation those websites through which our
                users, associates, and business partners may complete
                transactions, and manage their accounts. We understand that you
                care about how your Private Information is used and shared, and
                we take your privacy seriously. Please read the following to
                learn more about this Privacy Policy. “Deep EcomInsights Private
                Limited”, “we” and “our” refer to Deep EcomInsights Private
                Limited and its operating subsidiaries and affiliates that adopt
                this Privacy policy.”You” and “Your” refer to the individuals
                visiting our sites, enrolling with us, as well as to the
                organisation or businesses that they represent. By visiting one
                of the Sites, you are accepting the practices outlined in this
                Privacy Policy, which is subject to modification as described
                below. It is your responsibility to review this Privacy Policy
                frequently and remain informed about any changes to it, so we
                encourage you to visit this page often.
              </p>
            </div>
          </section>
          <h2
            className="AutolinkHeader-module--header--1G1tm Markdown-module--h2--3_kQn"
            id="what-is-private-information"
          >
            WHAT IS PRIVATE INFORMATION?
            <a
              className="AutolinkHeader-module--anchor--36UpA AutolinkHeader-module--left-anchor--1SDoO"
              href="#what-is-private-information"
              aria-label="WHAT IS PRIVATE INFORMATION? permalink"
            >
              <svg
                focusable="false"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                width="20"
                height="20"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M29.25,6.76a6,6,0,0,0-8.5,0l1.42,1.42a4,4,0,1,1,5.67,5.67l-8,8a4,4,0,1,1-5.67-5.66l1.41-1.42-1.41-1.42-1.42,1.42a6,6,0,0,0,0,8.5A6,6,0,0,0,17,25a6,6,0,0,0,4.27-1.76l8-8A6,6,0,0,0,29.25,6.76Z"></path>
                <path d="M4.19,24.82a4,4,0,0,1,0-5.67l8-8a4,4,0,0,1,5.67,0A3.94,3.94,0,0,1,19,14a4,4,0,0,1-1.17,2.85L15.71,19l1.42,1.42,2.12-2.12a6,6,0,0,0-8.51-8.51l-8,8a6,6,0,0,0,0,8.51A6,6,0,0,0,7,28a6.07,6.07,0,0,0,4.28-1.76L9.86,24.82A4,4,0,0,1,4.19,24.82Z"></path>
              </svg>
            </a>
          </h2>
          <div className="bx--row">
            <p className="">“Private Information” means</p>
          </div>
          <ul className="bx--list--unordered Markdown-module--list--oZv7L">
            <li className="bx--list__item Markdown-module--list-item--32ShB">
              as to a natural person, any information that may be used uniquely
              to identify the person (other than in such person’s representative
              capacity on behalf of a business enterprise), including such
              information submitted in Candidate Profiles in connection with
              employment applications through our employment or recruitment
              pages. Such information includes first and last name, birth dates,
              social security number, driver’s license, gender, race, personal
              (as opposed to business) e-mail addresses, credit information,
              home phone numbers or addresses, employment and educational
              history.
            </li>
            <li className="bx--list__item Markdown-module--list-item--32ShB">
              as to a business enterprise, any banking or confidential financial
              information that it does not generally share with third parties
              but that is shared with us in any credit application process or
              business transaction.
            </li>
            <li className="bx--list__item Markdown-module--list-item--32ShB">
              “Private information” does not include, without limitation,
              aggregated information (i.e. information presented in summary or
              statistical form which does not contain data that would permit the
              identification of a specific individual without significant
              effort).
            </li>
            <li className="bx--list__item Markdown-module--list-item--32ShB">
              A “business enterprise” or “business” means any sole
              proprietorship, partnership, corporation, limited partnership,
              limited liability partnership or company, business group or
              cooperative.
            </li>
          </ul>
          <h2
            className="AutolinkHeader-module--header--1G1tm Markdown-module--h2--3_kQn"
            id="how-we-gather-information"
          >
            How we gather information?
            <a
              className="AutolinkHeader-module--anchor--36UpA AutolinkHeader-module--left-anchor--1SDoO"
              href="#how-we-gather-information"
              aria-label="How we gather information? permalink"
            >
              <svg
                focusable="false"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                width="20"
                height="20"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M29.25,6.76a6,6,0,0,0-8.5,0l1.42,1.42a4,4,0,1,1,5.67,5.67l-8,8a4,4,0,1,1-5.67-5.66l1.41-1.42-1.41-1.42-1.42,1.42a6,6,0,0,0,0,8.5A6,6,0,0,0,17,25a6,6,0,0,0,4.27-1.76l8-8A6,6,0,0,0,29.25,6.76Z"></path>
                <path d="M4.19,24.82a4,4,0,0,1,0-5.67l8-8a4,4,0,0,1,5.67,0A3.94,3.94,0,0,1,19,14a4,4,0,0,1-1.17,2.85L15.71,19l1.42,1.42,2.12-2.12a6,6,0,0,0-8.51-8.51l-8,8a6,6,0,0,0,0,8.51A6,6,0,0,0,7,28a6.07,6.07,0,0,0,4.28-1.76L9.86,24.82A4,4,0,0,1,4.19,24.82Z"></path>
              </svg>
            </a>
          </h2>
          <div className="bx--row">
            <p className="">
              When you visit our sites or enroll with us through any medium, we
              may ask you for the private information so that we can provide you
              with the products, services and information requested by you,
              including financial information required in order to open a
              business account with us. We might ask you to provide your Name,
              shipping and billing address, email ID and payment related info.
            </p>
          </div>
          <h2
            className="AutolinkHeader-module--header--1G1tm Markdown-module--h2--3_kQn"
            id="what-information-do-we-gather"
          >
            What information do we gather?
            <a
              className="AutolinkHeader-module--anchor--36UpA AutolinkHeader-module--left-anchor--1SDoO"
              href="#what-information-do-we-gather"
              aria-label="What information do we gather? permalink"
            >
              <svg
                focusable="false"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                width="20"
                height="20"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M29.25,6.76a6,6,0,0,0-8.5,0l1.42,1.42a4,4,0,1,1,5.67,5.67l-8,8a4,4,0,1,1-5.67-5.66l1.41-1.42-1.41-1.42-1.42,1.42a6,6,0,0,0,0,8.5A6,6,0,0,0,17,25a6,6,0,0,0,4.27-1.76l8-8A6,6,0,0,0,29.25,6.76Z"></path>
                <path d="M4.19,24.82a4,4,0,0,1,0-5.67l8-8a4,4,0,0,1,5.67,0A3.94,3.94,0,0,1,19,14a4,4,0,0,1-1.17,2.85L15.71,19l1.42,1.42,2.12-2.12a6,6,0,0,0-8.51-8.51l-8,8a6,6,0,0,0,0,8.51A6,6,0,0,0,7,28a6.07,6.07,0,0,0,4.28-1.76L9.86,24.82A4,4,0,0,1,4.19,24.82Z"></path>
              </svg>
            </a>
          </h2>
          <ul className="bx--list--unordered Markdown-module--list--oZv7L">
            <li className="bx--list__item Markdown-module--list-item--32ShB">
              Name and contact details including email ID
            </li>
            <li className="bx--list__item Markdown-module--list-item--32ShB">
              Shipping and billing address
            </li>
            <li className="bx--list__item Markdown-module--list-item--32ShB">
              Financial information.
            </li>
          </ul>
          <h2
            className="AutolinkHeader-module--header--1G1tm Markdown-module--h2--3_kQn"
            id="we-may-gather-information-from-the-sites-by-cookies-and-internet-monitoring-technologies."
          >
            We may gather information from the Sites by cookies and Internet
            monitoring technologies.
            <a
              className="AutolinkHeader-module--anchor--36UpA AutolinkHeader-module--left-anchor--1SDoO"
              href="#we-may-gather-information-from-the-sites-by-cookies-and-internet-monitoring-technologies."
              aria-label="We may gather information from the Sites by cookies and Internet monitoring technologies. permalink"
            >
              <svg
                focusable="false"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                width="20"
                height="20"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M29.25,6.76a6,6,0,0,0-8.5,0l1.42,1.42a4,4,0,1,1,5.67,5.67l-8,8a4,4,0,1,1-5.67-5.66l1.41-1.42-1.41-1.42-1.42,1.42a6,6,0,0,0,0,8.5A6,6,0,0,0,17,25a6,6,0,0,0,4.27-1.76l8-8A6,6,0,0,0,29.25,6.76Z"></path>
                <path d="M4.19,24.82a4,4,0,0,1,0-5.67l8-8a4,4,0,0,1,5.67,0A3.94,3.94,0,0,1,19,14a4,4,0,0,1-1.17,2.85L15.71,19l1.42,1.42,2.12-2.12a6,6,0,0,0-8.51-8.51l-8,8a6,6,0,0,0,0,8.51A6,6,0,0,0,7,28a6.07,6.07,0,0,0,4.28-1.76L9.86,24.82A4,4,0,0,1,4.19,24.82Z"></path>
              </svg>
            </a>
          </h2>
          <ul className="bx--list--unordered Markdown-module--list--oZv7L">
            <li className="bx--list__item Markdown-module--list-item--32ShB">
              Cookies are small text files stored by your browser in your
              computer when you visit our Sites. We use cookies to improve the
              Sites and make them easier to use. Cookies permit us to recognise
              users and avoid repetitive requests for the same information.
              Cookies also assist us in identifying the types of browsers and
              operating systems used most by our customers, and how visitors
              move through the Sites. All of this information enables us to
              improve the Sites and tailor them to our customers’ needs and
              preferences. We use cookies for a variety of purposes intended to
              make our Sites a better service for you. We reserve the right to
              use cookies to deliver content specific to your needs and
              interests. We may also use cookies to track user trends and
              patterns in order to better understand and improve areas of our
              sites that our users find valuable. In addition, our servers track
              various technical details about your visit, such as your
              computer’s Internet protocol address and the pages that you
              visited.
            </li>
            <li className="bx--list__item Markdown-module--list-item--32ShB">
              Most browsers will accept cookies until you change your browser
              settings to refuse them. At any time, you can disable the use of
              cookies in your browser and still have access to much of the
              information on the Sites. However, you may not be able to access
              your account information or complete transactions on the Site
              unless your permit the use of cookies.
            </li>
            <li className="bx--list__item Markdown-module--list-item--32ShB">
              We also reserve the right to use outside companies to display ads
              on our sites. These ads may contain cookies. Cookies received with
              banner ads are collected by such outside companies, and we do not
              have access to this information.
            </li>
            <li className="bx--list__item Markdown-module--list-item--32ShB">
              We may from time to time offer interactive or social aspects on
              the Sites. For example, if you choose to express an opinion in an
              online forum, respond to user polls, or enter any contests, we may
              ask you for Private Information. If you request to sign up for
              newsletters or other promotional materials, or if you send an
              inquiry to one of our customer service representatives, we may
              store a record of your request along with any Private Information
              allowing us to respond to your inquiry.{" "}
            </li>
          </ul>
          <h2
            className="AutolinkHeader-module--header--1G1tm Markdown-module--h2--3_kQn"
            id="how-we-use-private-information"
          >
            HOW we use private information?
            <a
              className="AutolinkHeader-module--anchor--36UpA AutolinkHeader-module--left-anchor--1SDoO"
              href="#how-we-use-private-information"
              aria-label="HOW we use private information? permalink"
            >
              <svg
                focusable="false"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                width="20"
                height="20"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M29.25,6.76a6,6,0,0,0-8.5,0l1.42,1.42a4,4,0,1,1,5.67,5.67l-8,8a4,4,0,1,1-5.67-5.66l1.41-1.42-1.41-1.42-1.42,1.42a6,6,0,0,0,0,8.5A6,6,0,0,0,17,25a6,6,0,0,0,4.27-1.76l8-8A6,6,0,0,0,29.25,6.76Z"></path>
                <path d="M4.19,24.82a4,4,0,0,1,0-5.67l8-8a4,4,0,0,1,5.67,0A3.94,3.94,0,0,1,19,14a4,4,0,0,1-1.17,2.85L15.71,19l1.42,1.42,2.12-2.12a6,6,0,0,0-8.51-8.51l-8,8a6,6,0,0,0,0,8.51A6,6,0,0,0,7,28a6.07,6.07,0,0,0,4.28-1.76L9.86,24.82A4,4,0,0,1,4.19,24.82Z"></path>
              </svg>
            </a>
          </h2>
          <ul className="bx--list--unordered Markdown-module--list--oZv7L">
            <li className="bx--list__item Markdown-module--list-item--32ShB">
              Book keeping purposes
            </li>
            <li className="bx--list__item Markdown-module--list-item--32ShB">
              Marketing purposes.
            </li>
          </ul>
          <h2
            className="AutolinkHeader-module--header--1G1tm Markdown-module--h2--3_kQn"
            id="opt-out-procedures"
          >
            OPT OUT PROCEDURES
            <a
              className="AutolinkHeader-module--anchor--36UpA AutolinkHeader-module--left-anchor--1SDoO"
              href="#opt-out-procedures"
              aria-label="OPT OUT PROCEDURES permalink"
            >
              <svg
                focusable="false"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                width="20"
                height="20"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M29.25,6.76a6,6,0,0,0-8.5,0l1.42,1.42a4,4,0,1,1,5.67,5.67l-8,8a4,4,0,1,1-5.67-5.66l1.41-1.42-1.41-1.42-1.42,1.42a6,6,0,0,0,0,8.5A6,6,0,0,0,17,25a6,6,0,0,0,4.27-1.76l8-8A6,6,0,0,0,29.25,6.76Z"></path>
                <path d="M4.19,24.82a4,4,0,0,1,0-5.67l8-8a4,4,0,0,1,5.67,0A3.94,3.94,0,0,1,19,14a4,4,0,0,1-1.17,2.85L15.71,19l1.42,1.42,2.12-2.12a6,6,0,0,0-8.51-8.51l-8,8a6,6,0,0,0,0,8.51A6,6,0,0,0,7,28a6.07,6.07,0,0,0,4.28-1.76L9.86,24.82A4,4,0,0,1,4.19,24.82Z"></path>
              </svg>
            </a>
          </h2>
          <div className="bx--row">
            <p className="">
              To the extent required by law, and except as set forth above, so
              long as you have provided your email address to us, if we intend
              to use or share your Private Information beyond the scope
              described above or in a manner that we reasonably determine to be
              a purpose incompatible with the purpose(s) for which it was
              originally collected, we will endeavor to contact you and provide
              you with the ability to prevent the sharing of this information
              through “opt out” elections. If we do provide opt out elections
              with respect to new uses, even if not legally required to do so,
              such opt out elections will be treated as affirmative consent to
              the specified uses if you do not respond in a manner and within
              the period specified in the election to deny us the right to make
              such use.
            </p>
          </div>
          <h2
            className="AutolinkHeader-module--header--1G1tm Markdown-module--h2--3_kQn"
            id="is-private-information-secure"
          >
            IS PRIVATE INFORMATION SECURE?
            <a
              className="AutolinkHeader-module--anchor--36UpA AutolinkHeader-module--left-anchor--1SDoO"
              href="#is-private-information-secure"
              aria-label="IS PRIVATE INFORMATION SECURE? permalink"
            >
              <svg
                focusable="false"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                width="20"
                height="20"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M29.25,6.76a6,6,0,0,0-8.5,0l1.42,1.42a4,4,0,1,1,5.67,5.67l-8,8a4,4,0,1,1-5.67-5.66l1.41-1.42-1.41-1.42-1.42,1.42a6,6,0,0,0,0,8.5A6,6,0,0,0,17,25a6,6,0,0,0,4.27-1.76l8-8A6,6,0,0,0,29.25,6.76Z"></path>
                <path d="M4.19,24.82a4,4,0,0,1,0-5.67l8-8a4,4,0,0,1,5.67,0A3.94,3.94,0,0,1,19,14a4,4,0,0,1-1.17,2.85L15.71,19l1.42,1.42,2.12-2.12a6,6,0,0,0-8.51-8.51l-8,8a6,6,0,0,0,0,8.51A6,6,0,0,0,7,28a6.07,6.07,0,0,0,4.28-1.76L9.86,24.82A4,4,0,0,1,4.19,24.82Z"></path>
              </svg>
            </a>
          </h2>
          <ul className="bx--list--unordered Markdown-module--list--oZv7L">
            <li className="bx--list__item Markdown-module--list-item--32ShB">
              The security of Private Information is a priority for us. We
              implement measures that we believe in good faith will protect this
              information to the extent reasonably feasible in light of industry
              practices. Our measures may include restricted access to certain
              of our systems (including physical and electronic safeguards, such
              as passwords, encrypting sensitive information (as described
              below)). We may also restrict user access commensurate with
              responsibilities and institute procedural safeguards, such as
              identity authentication procedures. We train our employees in the
              proper handling of Private Information. We also maintain a
              disaster recovery and continuity plan to help guard against
              unforeseen disruptions.{" "}
            </li>
            <li className="bx--list__item Markdown-module--list-item--32ShB">
              We may also use encryption technology for certain Private
              Information used internally within our company.
            </li>
          </ul>
          <h2
            className="AutolinkHeader-module--header--1G1tm Markdown-module--h2--3_kQn"
            id="links-to-other-sites"
          >
            LINKS TO OTHER SITES
            <a
              className="AutolinkHeader-module--anchor--36UpA AutolinkHeader-module--left-anchor--1SDoO"
              href="#links-to-other-sites"
              aria-label="LINKS TO OTHER SITES permalink"
            >
              <svg
                focusable="false"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                width="20"
                height="20"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M29.25,6.76a6,6,0,0,0-8.5,0l1.42,1.42a4,4,0,1,1,5.67,5.67l-8,8a4,4,0,1,1-5.67-5.66l1.41-1.42-1.41-1.42-1.42,1.42a6,6,0,0,0,0,8.5A6,6,0,0,0,17,25a6,6,0,0,0,4.27-1.76l8-8A6,6,0,0,0,29.25,6.76Z"></path>
                <path d="M4.19,24.82a4,4,0,0,1,0-5.67l8-8a4,4,0,0,1,5.67,0A3.94,3.94,0,0,1,19,14a4,4,0,0,1-1.17,2.85L15.71,19l1.42,1.42,2.12-2.12a6,6,0,0,0-8.51-8.51l-8,8a6,6,0,0,0,0,8.51A6,6,0,0,0,7,28a6.07,6.07,0,0,0,4.28-1.76L9.86,24.82A4,4,0,0,1,4.19,24.82Z"></path>
              </svg>
            </a>
          </h2>
          <div className="bx--row">
            <p className="">
              You should be aware that this Privacy Policy applies only to the
              Sites. Importantly, it does not apply to any other websites to
              which a link may be provided on the Sites. However, once you
              decide to leave our website to access these links, you should note
              that we do not have any control over these websites. Therefore, we
              cannot be responsible for the protection and privacy of any
              information which you may provide whilst visit such sites and such
              sites are not governed by this privacy statement. You should
              exercise caution and look at the privacy statement applicable to
              the website in question.
            </p>
          </div>
          <h2
            className="AutolinkHeader-module--header--1G1tm Markdown-module--h2--3_kQn"
            id="controlling-your-personal-information."
          >
            Controlling your personal information.
            <a
              className="AutolinkHeader-module--anchor--36UpA AutolinkHeader-module--left-anchor--1SDoO"
              href="#controlling-your-personal-information."
              aria-label="Controlling your personal information. permalink"
            >
              <svg
                focusable="false"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                width="20"
                height="20"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M29.25,6.76a6,6,0,0,0-8.5,0l1.42,1.42a4,4,0,1,1,5.67,5.67l-8,8a4,4,0,1,1-5.67-5.66l1.41-1.42-1.41-1.42-1.42,1.42a6,6,0,0,0,0,8.5A6,6,0,0,0,17,25a6,6,0,0,0,4.27-1.76l8-8A6,6,0,0,0,29.25,6.76Z"></path>
                <path d="M4.19,24.82a4,4,0,0,1,0-5.67l8-8a4,4,0,0,1,5.67,0A3.94,3.94,0,0,1,19,14a4,4,0,0,1-1.17,2.85L15.71,19l1.42,1.42,2.12-2.12a6,6,0,0,0-8.51-8.51l-8,8a6,6,0,0,0,0,8.51A6,6,0,0,0,7,28a6.07,6.07,0,0,0,4.28-1.76L9.86,24.82A4,4,0,0,1,4.19,24.82Z"></path>
              </svg>
            </a>
          </h2>
          <div className="bx--row">
            <p className="">
              We will not sell, distribute or lease your personal information to
              third parties unless we are required by law to do so. You may
              request details of personal information about you that we hold
              under the Data Protection Act 1998.{" "}
            </p>
          </div>
        </div>
        <div
          style={{
            height: "40px",
          }}
        ></div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default Privacy;
