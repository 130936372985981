import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DemoModal } from "../InfoModal";
// import "./Pricing.css";

const Pricing = () => {
  const pricingData = [
    {
      planName: "Beginner Seller",
      pricing: {
        quarterly: {
          totalPrice: 3000,
          discount: 0,
          pricePerMonth: 1000,
          billingCycle: "3 Months",
        },
        halfYearly: {
          totalPrice: 6000,
          discount: 5,
          discountedPrice: 5700,
          pricePerMonth: 950,
          billingCycle: "6 Months",
        },
        yearly: {
          totalPrice: 12000,
          discount: 10,
          discountedPrice: 10800,
          pricePerMonth: 900,
          billingCycle: "12 Months",
        },
      },
      features: [
        "Upto 500 Orders/Month",
        "2 Marketplace",
        "1 GST No.",
        "Tally Sync",
        "Reports",
      ],
    },
    {
      planName: "Emerging Seller",
      pricing: {
        quarterly: {
          totalPrice: 6000,
          discount: 0,
          pricePerMonth: 2000,
          billingCycle: "3 Months",
        },
        halfYearly: {
          totalPrice: 12000,
          discount: 5,
          discountedPrice: 11400,
          pricePerMonth: 1900,
          billingCycle: "6 Months",
        },
        yearly: {
          totalPrice: 24000,
          discount: 10,
          discountedPrice: 21600,
          pricePerMonth: 1800,
          billingCycle: "12 Months",
        },
      },
      features: [
        "Upto 1000 Orders/Month",
        "4 Marketplace",
        "2 or more GST No.",
        "Tally Sync",
        "Reports",
        "Data Insights",
        "Profit Tracking",
      ],
    },
    {
      planName: "Scaling Seller",
      pricing: {
        quarterly: {
          totalPrice: 9000,
          discount: 0,
          pricePerMonth: 3000,
          billingCycle: "3 Months",
        },
        halfYearly: {
          totalPrice: 18000,
          discount: 5,
          discountedPrice: 17100,
          pricePerMonth: 2850,
          billingCycle: "6 Months",
        },
        yearly: {
          totalPrice: 36000,
          discount: 10,
          discountedPrice: 32400,
          pricePerMonth: 2700,
          billingCycle: "12 Months",
        },
      },
      features: [
        "Upto 2000 Orders/Month",
        "4 Marketplace",
        "2 or more GST No.",
        "Tally Sync",
        "Reports",
        "Data Insights",
        "Profit Tracking",
      ],
    },
  ];

  const [showModal,setShowModal] = useState(false)


  const [selectedPlans, setSelectedPlans] = useState(
    pricingData.map((plan) => ({
      planName: plan.planName,
      selectedBillingCycle: "quarterly",
    }))
  );

  const updatePlanDetails = (planName, billingCycle) => {
    setSelectedPlans((prevPlans) =>
      prevPlans.map((p) =>
        p.planName === planName
          ? { ...p, selectedBillingCycle: billingCycle }
          : p
      )
    );
  };

  return (
    <div id="pricing" className="pricing">
      <div className="container">
        <div className="heading col-12">
          <h5>Pricing</h5>
          <h6>Flexible Plans Tailored to Your Needs</h6>
          <ul className="list-inline">
            <li className="list-inline-item">Pay As You Go</li>
            {/* <li className="list-inline-item">|</li> */}
            {/* <li className="list-inline-item">
                  Pricing model: Starting at INR 500 per month
                </li> */}
          </ul>
          <div className="sep-element primary" />
        </div>
        <div className="row">
          {pricingData.map((plan) => {
            const selectedPlan = selectedPlans.find(
              (p) => p.planName === plan.planName
            );
            const selectedBillingCycle = selectedPlan
              ? selectedPlan.selectedBillingCycle
              : "quarterly";
            const planDetails = plan.pricing[selectedBillingCycle];

            return (
              <div className="col-md-4" key={plan.planName}>
                <div className="pricing-box popular">
                  <h3>{plan.planName}</h3>
                  <div className="price font-title">
                    <span className="num">
                      <span className="symbol">₹</span>
                      {planDetails.pricePerMonth}
                    </span>
                    <span className="mon">/month</span>
                  </div>
                  <p className="desc">
                    Billed ₹
                    {planDetails.discountedPrice || planDetails.totalPrice}/
                    {planDetails.billingCycle}
                  </p>
                  <div className="pricing-select">
                    <select
                      className="form-control"
                      value={selectedBillingCycle}
                      onChange={(e) =>
                        updatePlanDetails(plan.planName, e.target.value)
                      }
                    >
                      <option value="quarterly">Quarterly Plan</option>
                      <option value="halfYearly">Half-Yearly Plan</option>
                      <option value="yearly">Yearly Plan</option>
                    </select>
                  </div>
                  <div onClick={()=>setShowModal(true)} className="buy-btn">
                    <span>Buy Now</span>
                  </div>
                  <div className="divider"></div>
                  <ul className="rights-list expanded">
                    {plan.features.map((feature, index) => (
                      <li className="right-item" key={index}>
                        <img
                          src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg"
                          alt="check"
                        />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
          <div
          style={{
            display:'flex',
            justifyContent:"center"
          }}>
          <Link to="/pricing">
          <p style={{
            textAlign:'center',
            textDecoration:"underline",
            cursor:"pointer",
            width:'fit-content'
          }}>View More</p></Link>
          </div>
        </div>
        <DemoModal showModal={showModal} heading="Buy Now" onClose={()=>setShowModal(false)} />
        {/* <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="pricing-box popular">
                <h3>Silver Plan</h3>
                <div className="price font-title">
                  <span className="num">
                    <span className="symbol">₹</span>
                    145.83
                  </span>
                  <span className="mon">/month</span>
                </div>
                <p className="desc">Billed ₹1750/1 Year</p>
                <div className="pricing-select">
                  <select name="" id="" className="form-control">
                      <option value="">1 Year Plan</option>
                      <option value="">1 Year Plan</option>
                  </select>
                </div>
                <div className="buy-btn">
                  <span>Buy Now</span>
                </div>
                <div data-v-10f5b9e9="" className="divider"></div>
                <ul className="rights-list expanded">
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >Upto 500 Orders/Month</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >2 Marketplace</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >1 GST No.</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >Tally Sync</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >Reports</span>       
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="pricing-box popular">
                <h3>Gold Plan</h3>
                <div className="price font-title">
                  <span className="num">
                    <span className="symbol">₹</span>
                    145.83
                  </span>
                  <span className="mon">/month</span>
                </div>
                <p className="desc">Billed ₹1750/1 Year</p>
                <div className="pricing-select">
                  <select name="" id="" className="form-control">
                      <option value="">1 Year Plan</option>
                      <option value="">1 Year Plan</option>
                  </select>
                </div>
                <div className="buy-btn">
                  <span>Buy Now</span>
                </div>
                <div data-v-10f5b9e9="" className="divider"></div>
                <ul className="rights-list expanded">
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >Upto 1000 Order/Month</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >4 Marketplace</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >2 or More GST No.</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >Tally Sync</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >Reports</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >Data Insights</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >Profit Tracking</span>       
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="pricing-box popular">
                <h3>Platinum Plan</h3>
                <div className="price font-title">
                  <span className="num">
                    <span className="symbol">₹</span>
                    145.83
                  </span>
                  <span className="mon">/month</span>
                </div>
                <p className="desc">Billed ₹1750/1 Year</p>
                <div className="pricing-select">
                  <select name="" id="" className="form-control">
                      <option value="">1 Year Plan</option>
                      <option value="">1 Year Plan</option>
                  </select>
                </div>
                <div className="buy-btn">
                  <span>Buy Now</span>
                </div>
                <div data-v-10f5b9e9="" className="divider"></div>
                <ul className="rights-list expanded">
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >Upto 2000 Orders</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >4 Marketplace</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >2 or More GST No.</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >Tally Sync</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >Reports</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >Data Insights</span>       
                  </li>
                  <li className="right-item">
                    <img  src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg" alt="check"/>
                    <span >Profit Tracking</span>       
                  </li>
                </ul>
              </div>
            </div>
            
          </div> */}
      </div>
      {/* <div className="col-md-12" style={{
         display:"flex",
         justifyContent:'center',
         margin:"100px 0px"
        }}>
              <div className="pricing-box">
                <h3>Above 8000</h3>
                <div className="buy-btn">
                  <span>Let's Speak</span>
                </div>
              </div>
            </div> */}
    </div>
  );
};

export default Pricing;
