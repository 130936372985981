import React from 'react'
import "./Benefits.css";
import "./BenefitData";
import BenefitCard from '../BenefitCard/BenefitCard';
import {BenefitData} from './BenefitData';

const Benefits = () => {
    return (
        <div id='benefits' className='benefits container-fluid'>
            <div className="container">
                <div className="row">
                    <div className="heading col-12 position-relative">
                        <h1>Benefits</h1>
                        <p>Focus on your business, Not on Sorting Reports.</p>
                        <div className="sep-element"/>
                    </div>
                </div>
                <div className="row benefits-row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-11 mx-auto">
                        <div className="card-section row row-left">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-5" data-aos="zoom-in">
                                <BenefitCard {...BenefitData[0]} />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-5 text-end offset-lg-2" data-aos="zoom-in">
                                <BenefitCard {...BenefitData[1]} />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-5 padding-right-div" data-aos="zoom-in">
                                <BenefitCard {...BenefitData[2]} />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-5 padding-left-div text-end offset-lg-2" data-aos="zoom-in">
                                <BenefitCard {...BenefitData[3]} />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-5 pt-0-inner" data-aos="zoom-in">
                                <BenefitCard {...BenefitData[4]} />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-5 pt-0-inner text-end offset-lg-2" data-aos="zoom-in">
                                <BenefitCard {...BenefitData[5]} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Benefits
