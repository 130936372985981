import React, { useState } from "react";
import "./LandingPage.css";
import LandingPageImg from "../../images/LandingPageImg.png";
import {DemoModal} from "../InfoModal"

const LandingPage = () => {
  const [showModal,setShowModal] = useState(false)
  return (
    <>
      <div className="banner-main container-fluid">
        <div className="container">
          <div className="row">
            <div className="left-section col-md-6" data-aos="fade-right">
              <h1>
                Maximize <span>Your E-commerce</span> Profits with Automated
                Accounting & Payment <span>Reconciliation!</span>
              </h1>
              <ul className="list-inline">
                <li>
                DeepEcom tracks real profits across multiple marketplaces.
                </li>
                <li>Automate Tally accounting.</li>
                <li>Reconcile payments effortlessly.</li>
              </ul>
              <button onClick={()=>setShowModal(true)}>Book Demo</button>
            </div>
            <div
              className="col-md-6 image-section d-flex justify-content-center"
              // data-aos="fade-up"
            >
              <img
                className="img-fluid"
                src={LandingPageImg}
                alt="dashboardImg"
              />
            </div>
          </div>
        </div>
      </div>
      <DemoModal showModal={showModal} onClose={()=>setShowModal(false)} heading="Book Demo" />
    </>
  );
};

export default LandingPage;

