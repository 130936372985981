import React, { useEffect, useState } from 'react';
import "./Navbar.css";
import { Link, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const handleProductClick = (path, sectionId) => {
        // e.preventDefault();
        navigate(`${path}/${sectionId}`); 
        setIsProductDropdownOpen(false); 
    };
    const waitForElement = (id, retries = 10, delay = 300) => {
        return new Promise((resolve, reject) => {
            let attempts = 0;
    
            const interval = setInterval(() => {
                const element = document.getElementById(id);
                if (element) {
                    clearInterval(interval);
                    resolve(element);
                }
                attempts += 1;
                if (attempts >= retries) {
                    clearInterval(interval);
                    reject('Element not found');
                }
            }, delay);
        });
    };
    
    const handleScroll = async (e, id) => {
        e.preventDefault();
    
        // let originUrl = window.location.origin;
    
        if (window.location.pathname !== '/') {
            // Only navigate if the current page is not the home page
            navigate('/');
        }
    
        try {
            // Wait for the element to be available
            const element = await waitForElement(id);
    
            // Once the element is found, scroll to it
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
    
            // Close the menu and dropdowns
            setIsMobileMenuOpen(false);
            setIsProductDropdownOpen(false);
        } catch (error) {
            console.error(error); // If element not found after retries
        }
    };
    

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    }, []);

    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [isMobileMenuOpen]);


    const isSticky = (e) => {
        const header = document.querySelector('.navbar');
        const scrollTop = window.scrollY;
        scrollTop >= 60 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const toggleProductDropdown = () => {
        // navigate("products")
    };

    return (
        <div className='navbar'>
            <div className="container">
                <div className="row w-100 align-items-center justify-content-between">
                    <div className="logo w-auto">
                        <a href="/">Deep<span>ecom</span></a>
                    </div>
                    <div className={`navLink w-auto ${isMobileMenuOpen ? 'open' : ''}`}>
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item"><a href="#about" onClick={(e) => handleScroll(e, 'about')}>About</a></li>

                            
                            <li className="list-inline-item products-dropdown" onMouseEnter={()=>{setIsProductDropdownOpen(true)}} onMouseLeave={()=>{setIsProductDropdownOpen(false)}} >
                                <div className="dropdown-toggles" onClick={toggleProductDropdown}>
                                    <a href="" onClick={(e) => e.preventDefault()}>
                                        Features
                                    </a>
                                    <span className="dropdown-icon">{isProductDropdownOpen ? '▲' : '▼'}</span>
                                </div>
                                {isProductDropdownOpen && (
                                    <ul className={`dropdown-menu ${isProductDropdownOpen ? 'open' : ''}`}>
                                        <li><a onClick={() =>handleProductClick("/products","RealProfitTracking")}>Real Profit Tracking<FontAwesomeIcon icon={faAngleRight} /></a></li>
                                        <li><a onClick={() => handleProductClick("/products","TallyIntegration")}>Tally Integration<FontAwesomeIcon icon={faAngleRight} /></a></li>
                                        <li><a onClick={() => handleProductClick("/products","AutomatedPayment")}>Automated Payment Reconciliation<FontAwesomeIcon icon={faAngleRight} /></a></li>
                                        <li><a onClick={() => handleProductClick("/products","GstTax")}>GST & Tax Compliance<FontAwesomeIcon icon={faAngleRight} /></a></li>
                                        <li><a onClick={() => handleProductClick("/products","MultiMarketPlace")}>Multi-Marketplace Support<FontAwesomeIcon icon={faAngleRight} /></a></li>
                                        <li><a onClick={() => handleProductClick("/products","AiPowered")}>AI-Powered Insights<FontAwesomeIcon icon={faAngleRight} /></a></li>
                                    </ul>
                                )}
                            </li>

                            <li className="list-inline-item"><Link to="/pricing">Pricing</Link></li>
                            <li className="list-inline-item"><Link to="#integrations" onClick={(e) => handleScroll(e, 'integrations')}>Integrations</Link></li>
                            <li className="list-inline-item"><Link to="#benefits" onClick={(e) => handleScroll(e, 'benefits')}>Benefits</Link></li>
                            <li className="list-inline-item"><Link to="#testimonial" onClick={(e) => handleScroll(e, 'testimonial')}>Testimonials</Link></li>
                            <li className="list-inline-item"><Link to="#client" onClick={(e) => handleScroll(e, 'client')}>Clients</Link></li>
                            <li className="list-inline-item"><Link to="#faq" onClick={(e) => handleScroll(e, 'faq')}>FAQ</Link></li>
                            <li className="list-inline-item"><Link to="#contact" onClick={(e) => handleScroll(e, 'contact')}>Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="hamburger-menu w-auto" onClick={toggleMobileMenu}>
                        <div className={`bar ${isMobileMenuOpen ? 'open' : ''}`}></div>
                        <div className={`bar ${isMobileMenuOpen ? 'open' : ''}`}></div>
                        <div className={`bar ${isMobileMenuOpen ? 'open' : ''}`}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;