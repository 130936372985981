import React from "react";
import "./Testimonials.css";
import {TestimonialData} from "./TestimonialData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";

const Testimonials = () => {
    return (
        <div id="testimonial" className="testimonials container-fluid">
            <div className="container">
                <div className="row">
                    <div className="heading col-12 col-12 col-sm-12 col-md-12 col-lg-8 mx-auto">
                        <h5>Our Testimonials</h5>
                        <div className="sep-element primary"/>
                    </div>
                    <div className="col-12">
                        <div id="testimonialCarousel" className="carousel slide" data-bs-ride="carousel">
                            {/* Carousel Items */}
                            <div className="carousel-inner">
                                {TestimonialData.map((testimonial, index) => (
                                    <div
                                        className={`carousel-item ${index === 0 ? "active" : ""}`}
                                        key={index}
                                    >
                                        <div className="carousel-caption">
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-6">
                                                    <div className="row mx-0 testimonials-card" data-aos="zoom-out" >
                                                        <div className="col-12">
                                                            <p>{testimonial.description}</p>
                                                        </div>
                                                    </div>
                                                    <div className="testimonials-client-details">
                                                        <div className="client-img">
                                                            <img className="img-fluid" alt="" src="https://guardteam.true-emotions.studio/wp-content/uploads/2017/01/t2.jpg"/>
                                                        </div>
                                                        <div className="client-details-inner">
                                                            <div id="image-caption">
                                                                <h5>{testimonial.name}
                                                                    <span>({testimonial.designation})</span></h5>
                                                            </div>
                                                            <div className="review">
                                                                <h6>{testimonial.review}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-6">
                                                    <div className="row mx-0 testimonials-card" data-aos="zoom-out">
                                                        <div className="col-12">
                                                            <p>{testimonial.description}</p>
                                                        </div>
                                                    </div>
                                                    <div className="testimonials-client-details">
                                                        <div className="client-img">
                                                            <img className="img-fluid" alt="" src="https://guardteam.true-emotions.studio/wp-content/uploads/2017/01/t2.jpg"/>
                                                        </div>
                                                        <div className="client-details-inner">
                                                            <div id="image-caption">
                                                                <h5>{testimonial.name}
                                                                    <span>({testimonial.designation})</span></h5>
                                                            </div>
                                                            <div className="review">
                                                                <h6>{testimonial.review}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Carousel Controls */}
                            <button
                                className="carousel-control-prev"
                                type="button"
                                data-bs-target="#testimonialCarousel"
                                data-bs-slide="prev"
                            >
                                <FontAwesomeIcon icon={faArrowLeft} className="arrowButton arrowLeft"/>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button
                                className="carousel-control-next"
                                type="button"
                                data-bs-target="#testimonialCarousel"
                                data-bs-slide="next"
                            >
                                <FontAwesomeIcon icon={faArrowRight} className="arrowButton arrowRight"/>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
