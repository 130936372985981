export const TestimonialData=[
    {
        name:"Priya D.",
        designation:'Business Analyst',
        description:"The business intelligence tools have given us exceptional ROI by identifying key areas for revenue growth and cost savings. Data-driven decisions are now a reality for our company.",
        review:"Exceptional ROI from Analytics"
    },
    {
        name:"Ashok V.",
        designation:'Finance Controller',
        description:"GST filing used to be a dreaded event every quarter. Since using DeepEcom, it's literally one click and we're done. The accuracy has improved, and we haven't faced a single discrepancy since.",
        review:"GST Filing is Now a Breeze"
    },
    {
        name:"Nisha Q.",
        designation:'Digital Strategist',
        description:"DeepEcom represents ecommerce at its finest – sophisticated, streamlined, and strategic. We saw a significant improvement in our overall operation within weeks.",
        review:"Ecommerce at Its Finest"
    },
    {
        name:"Sanjay K.",
        designation:'CFO',
        description:"Thanks to DeepEcom, our payment reconciliation hassles are gone, which has revolutionized our cash flow management. We are now able to recognize revenue faster and with more accuracy.",
        review:"Revolutionized Our Cash Flow"
    },
]
