import React from 'react';
import "./Products.css";
import ProductCard from '../ProductCard/ProductCard';
import ErpImage from "../../images/Erp.jpeg";
import PaymentReconcillation from "../../images/PaymentReconciliation.jpeg";
import BusinessAnalytics from "../../images/BusinessAnalytics.jpeg";


const Products = () => {
    const ProductData=[
        {
            imgSrc:ErpImage,
            headline:"Sync with ERP Like Never Before",
            title:"ERP Integration",
            description:"Bid farewell to the hassle of manual data entry. Our top-tier Tally ERP Integration automates and synchronizes your accounts, inventory, and sales effortlessly, ensuring your business financials are always in harmony."
        },
        {
            imgSrc:PaymentReconcillation,
            headline:"Reconcile Every Rupee with Ease",
            title:"Payment Reconciliation",
            description:"Payment discrepancies can be a thing of the past. DeepEcom's Payment Reconciliation feature matches each transaction, flags inconsistencies, and keeps your books precise — so you can focus on growing your profits, not chasing them"
        
        },
        {
            imgSrc:BusinessAnalytics,
            headline:"Insights That Inspire Intelligent Decisions",
            title:"Business Analytics and Intelligence",
            description:"Harness the power of your data with advanced analytics tools. Understand your customers, optimize your operations, and make strategic moves with confidence, backed by real-time intelligence culled from your sales and engagements."
        }
    ]
    
  return (
    <div id='products' className='products container-fluid'>
        <div className="container">
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 mx-auto text-center product-heading">
                    <h5>Our Products</h5>
                    <div className="sep-element primary"/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className='productsList'>
                          {ProductData.map((product,index) =>(
                            <ProductCard
                             key={index}
                             imgSrc={product.imgSrc}
                             headline={product.headline}
                             title={product.title}
                             description={product.description}
                             />
                           ))
                          }
                    </div>
                </div>
            </div>
        </div>
    </div>

  )
}

export default Products
