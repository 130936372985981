import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { faPhone, faHome, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const waitForElement = (id, retries = 10, delay = 300) => {
    return new Promise((resolve, reject) => {
        let attempts = 0;

        const interval = setInterval(() => {
            const element = document.getElementById(id);
            if (element) {
                clearInterval(interval);
                resolve(element);
            }
            attempts += 1;
            if (attempts >= retries) {
                clearInterval(interval);
                reject('Element not found');
            }
        }, delay);
    });
};


  const handleScroll = async (e, id) => {
    e.preventDefault();

    // let originUrl = window.location.origin;

    if (window.location.pathname !== '/') {
        // Only navigate if the current page is not the home page
        navigate('/');
    }

    try {
        // Wait for the element to be available
        const element = await waitForElement(id);

        // Once the element is found, scroll to it
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });

    } catch (error) {
        console.error(error); // If element not found after retries
    }
};
  return (
    <div className="footer container-fluid">
      <footer className="text-center text-lg-start text-white">
        <section className="social-links">
          <div className="container">
            <div className="row justify-content-between">
              <div className="w-auto d-flex align-items-center">
                <span>Get connected with us on social networks:</span>
              </div>
              <div className="w-auto">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <a
                      href="https://facebook.com"
                      className="social-links-tag"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://twitter.com"
                      className="social-links-tag"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://instagram.com"
                      className="social-links-tag"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://youtube.com"
                      className="social-links-tag"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="footer-bottom">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mb-4">
                <h6 className="text-uppercase fw-bold">DEEPECOM.</h6>
                <hr className="mb-4 mt-0 d-inline-block mx-auto" />
                <p>
                  DeepEcom is your trusted partner for automated e-commerce
                  accounting, payment reconciliation, and tax compliance.
                </p>
              </div>

              <div className="col-6 col-md-2 col-lg-2 col-xl-2 mb-4 d-none d-lg-block">
              </div>

              <div className="col-6 col-md-3 col-lg-2 col-xl-2 mb-4 col-12">
                <h6 className="text-uppercase fw-bold">Links</h6>
                <hr className="mb-4 mt-0 d-inline-block mx-auto" />
                <p>
                  <Link href="#!" className="text-white">
                    About
                  </Link>
                </p>
                <p>
                  <Link href="" className="text-white">
                    Features
                  </Link>
                </p>
                <p>
                  <Link to="/pricing" className="text-white">
                    Pricing
                  </Link>
                </p>
                <p>
                  <Link to="#integrations" onClick={(e) => handleScroll(e, 'integrations')} className="text-white">
                    Integrations
                  </Link>
                </p>
                <p>
                  <Link to="#benefits" onClick={(e) => handleScroll(e, 'benefits')} className="text-white">
                    Benefits
                  </Link>
                </p>
                <p>
                  <Link to="#testimonial" onClick={(e) => handleScroll(e, 'testimonial')} className="text-white">
                    Testimonials
                  </Link>
                </p>
                <p>
                  <Link to="#faq" onClick={(e) => handleScroll(e, 'faq')} className="text-white">
                    FAQ
                  </Link>
                </p>
                <p>
                  <Link to="/privacy-policy" style={{
                    color:"white"
                  }}>
                    Privacy
                  </Link>
                </p>
              </div>

              <div className="contact-links col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold">Contact</h6>
                <hr className="mb-4 mt-0 d-inline-block mx-auto" />
                <p>
                  <FontAwesomeIcon className="contact-icon" icon={faHome} />
                  Liberty Phase 2, Ragvilas Society, Koregaon Park, Pune,
                  Maharashtra 411001, India
                </p>
                <p>
                  <FontAwesomeIcon className="contact-icon" icon={faEnvelope} />{" "}
                  info@deepecom.com
                </p>
                <p>
                  <FontAwesomeIcon className="contact-icon" icon={faPhone} /> +
                  020 2615 1994/5
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="copyright text-center p-3">
          © 2025 Copyright: DeepECom
        </div>
      </footer>
    </div>
  );
};

export default Footer;
