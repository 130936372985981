import React from 'react'
import "./BenefitCard.css";

const BenefitCard = ({title,description,count}) => {
  return (
    <div className='benefit-card'>
        <h5><span>{count}</span> {title}</h5>
        <p>{description}</p>
    </div>
  )
}

export default BenefitCard
