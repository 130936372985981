import React from "react";
import "./Integration.css";
import { MarketPlaceServices, ErpServices , LogisticsServices,PaymentGatewayServices} from "./Services";

const Integration = () => {
  return (
    // <div id="integrations" className="integration container-fluid">
    //     <div className="container">
    //         <div className="row align-items-center integration-row">
    //             <div className="col-12 col-sm-12 col-md-12 col-lg-6">
    //                 <div className="row">
    //                     <div className="col-6 logo-div div-1" data-aos='fade-down-right'>
    //                         <img className="img-fluid" src={flipkartLogo} alt="fadekartLogo"/>
    //                     </div>
    //                     <div className="col-6 logo-div div-2" data-aos='fade-down-left'>
    //                         <img className="img-fluid" src={Myntra} alt="MyntraLogo"/>
    //                     </div>
    //                     <div className="col-6 logo-div div-3" data-aos='fade-up-right'>
    //                         <img className="img-fluid" src={Shopify} alt="ShopifyLogo"/>
    //                     </div>
    //                     <div className="col-6 logo-div div-4" data-aos='fade-up-right'>
    //                         <img className="amazonLogo img-fluid" src={AmazonLogo} alt="AmazonLogo"
    //                         />
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-12 col-sm-12 col-md-12 col-lg-6 heading">
    //                 <h5>Integrations</h5>
    //                 <h6>
    //                     A unified platform for different Marketplaces, <br/> ERP’s, Logistic
    //                     Partners, Payment <br/> Gateways.
    //                 </h6>
    //                 <p>
    //                     Automate your Ecommerce finance management with <br/> 50 + stable
    //                     integrations. Increase your efficiency and Save time with <br/> these
    //                     integrations.
    //                 </p>
    //             </div>
    //         </div>
    //     </div>
    // </div>
    <div id="integrations" className="integration container-fluid">
        <div class="heading col-12"><h5>Integrations</h5><p>Automate your Ecommerce finance management with <br/> 50 + stable
                        integrations. Increase your efficiency and Save time with <br/> these
                        integrations.</p><div class="sep-element primary"></div></div>
      <div className="case-studio container">
        <ul class="nav nav-pills case-studio-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <button
              class="nav-link active"
              id="marketPlace-tab"
              data-bs-toggle="tab"
              href="#marketPlace"
              role="tab"
              aria-controls="marketPlace"
              aria-selected="true"
            >
              Market Place
            </button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link"
              id="erp-tab"
              data-bs-toggle="tab"
              href="#erp"
              role="tab"
              aria-controls="erp"
              aria-selected="true"
            >
              ERP
            </button>
          </li>

          <li class="nav-item">
            <button
              class="nav-link"
              id="logistic-tab"
              data-bs-toggle="tab"
              href="#logistics"
              role="tab"
              aria-controls="logistic"
              aria-selected="false"
            >
              Logistics
            </button>
          </li>

          <li class="nav-item">
            <button
              class="nav-link"
              id="paymentGateway-tab"
              data-bs-toggle="tab"
              href="#paymentGateway"
              role="tab"
              aria-controls="paymentGateway"
              aria-selected="false"
            >
              Payment Gateway
            </button>
          </li>
        </ul>

        {/* Market Place Tab */}

        <div className="tab-content case-studio-tab-content">
          <div
            class="tab-pane fade active show"
            id="marketPlace"
            role="tabpanel"
            aria-labelledby="marketPlace-tab"
          >
            <div class="services-list-2">
              <div className="row">
                {MarketPlaceServices.map((service, index) => (
                  <div className="col-md-3" key={index}>
                    <div class="pool-box-list">
                      <a href="">
                        <i>
                          <img
                            data-src="images/pool-icon-home/web-icon.svg"
                            src={service.logo}
                            data-lazy=""
                            alt="Web tech link icon"
                            width="45"
                            height="35"
                            data-processed="true"
                          />
                        </i>
                        <small>{service.name}</small>
                      </a>
                    </div>
                  </div>
                ))}
                {/* <div className="col-md-3">
                  <div class="pool-box-list">
                    <a href="">
                      <i>
                        <img
                          data-src="images/pool-icon-home/web-icon.svg"
                          src="https://e7.pngegg.com/pngimages/474/219/png-clipart-tally-solutions-enterprise-resource-planning-tally-erp9-accounting-software-management-business-text-people-thumbnail.png"
                          data-lazy=""
                          alt="Web tech link icon"
                          width="35"
                          height="35"
                          data-processed="true"
                        />
                      </i>
                      <small>Flipkart</small>
                    </a>
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="pool-box-list">
                    <a href="">
                      <i>
                        <img
                          data-src="images/pool-icon-home/web-icon.svg"
                          src="https://e7.pngegg.com/pngimages/474/219/png-clipart-tally-solutions-enterprise-resource-planning-tally-erp9-accounting-software-management-business-text-people-thumbnail.png"
                          data-lazy=""
                          alt="Web tech link icon"
                          width="35"
                          height="35"
                          data-processed="true"
                        />
                      </i>
                      <small>Myntra</small>
                    </a>
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="pool-box-list">
                    <a href="">
                      <i>
                        <img
                          data-src="images/pool-icon-home/web-icon.svg"
                          src="https://e7.pngegg.com/pngimages/474/219/png-clipart-tally-solutions-enterprise-resource-planning-tally-erp9-accounting-software-management-business-text-people-thumbnail.png"
                          data-lazy=""
                          alt="Web tech link icon"
                          width="35"
                          height="35"
                          data-processed="true"
                        />
                      </i>
                      <small>Meesho</small>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* Erp Tab */}
        <div className="tab-content case-studio-tab-content">
          <div
            class="tab-pane"
            id="erp"
            role="tabpanel"
            aria-labelledby="erp-tab"
          >
            <div class="services-list-2">
              <div className="row">
              {ErpServices.map((service,index) =>(

                <div className="col-md-3" key={index}>
                  <div class="pool-box-list">
                    <a href="">
                      <i>
                        <img
                          // data-src="images/pool-icon-home/web-icon.svg"
                          src={service.logo}
                          data-lazy=""
                          alt="Web tech link icon"
                          width="45"
                          height="35"
                          data-processed="true"
                        />
                      </i>
                      <small>{service.name}</small>
                    </a>
                  </div>
                </div>
              ))}
                {/* <div className="col-md-3">
                  <div class="pool-box-list">
                    <a href="">
                      <i>
                        <img
                          data-src="images/pool-icon-home/web-icon.svg"
                          src="https://e7.pngegg.com/pngimages/474/219/png-clipart-tally-solutions-enterprise-resource-planning-tally-erp9-accounting-software-management-business-text-people-thumbnail.png"
                          data-lazy=""
                          alt="Web tech link icon"
                          width="35"
                          height="35"
                          data-processed="true"
                        />
                      </i>
                      <small>Ship Rocket</small>
                    </a>
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="pool-box-list">
                    <a href="">
                      <i>
                        <img
                          data-src="images/pool-icon-home/web-icon.svg"
                          src="https://e7.pngegg.com/pngimages/474/219/png-clipart-tally-solutions-enterprise-resource-planning-tally-erp9-accounting-software-management-business-text-people-thumbnail.png"
                          data-lazy=""
                          alt="Web tech link icon"
                          width="35"
                          height="35"
                          data-processed="true"
                        />
                      </i>
                      <small>Tally</small>
                    </a>
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="pool-box-list">
                    <a href="">
                      <i>
                        <img
                          data-src="images/pool-icon-home/web-icon.svg"
                          src="https://e7.pngegg.com/pngimages/474/219/png-clipart-tally-solutions-enterprise-resource-planning-tally-erp9-accounting-software-management-business-text-people-thumbnail.png"
                          data-lazy=""
                          alt="Web tech link icon"
                          width="35"
                          height="35"
                          data-processed="true"
                        />
                      </i>
                      <small>Tally</small>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* Payment Gateway Tab */}
        <div className="tab-content case-studio-tab-content">
          <div
            class="tab-pane"
            id="logistics"
            role="tabpanel"

          >
            <div class="services-list-2">
              <div className="row">
            {LogisticsServices.map((service,index)=>(

                <div className="col-md-3" key={index} >
                  <div class="pool-box-list">
                    <a href="">
                      <i>
                        <img
                          // data-src="images/pool-icon-home/web-icon.svg"
                          src={service.logo}
                          data-lazy=""
                          alt="Web tech link icon"
                          width="45"
                          height="35"
                          data-processed="true"
                        />
                      </i>
                      <small>{service.name}</small>
                    </a>
                  </div>
                </div>
            ))}
                {/* <div className="col-md-3">
                  <div class="pool-box-list">
                    <a href="">
                      <i>
                        <img
                          data-src="images/pool-icon-home/web-icon.svg"
                          src="https://e7.pngegg.com/pngimages/474/219/png-clipart-tally-solutions-enterprise-resource-planning-tally-erp9-accounting-software-management-business-text-people-thumbnail.png"
                          data-lazy=""
                          alt="Web tech link icon"
                          width="35"
                          height="35"
                          data-processed="true"
                        />
                      </i>
                      <small>Tally</small>
                    </a>
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="pool-box-list">
                    <a href="">
                      <i>
                        <img
                          data-src="images/pool-icon-home/web-icon.svg"
                          src="https://e7.pngegg.com/pngimages/474/219/png-clipart-tally-solutions-enterprise-resource-planning-tally-erp9-accounting-software-management-business-text-people-thumbnail.png"
                          data-lazy=""
                          alt="Web tech link icon"
                          width="35"
                          height="35"
                          data-processed="true"
                        />
                      </i>
                      <small>Tally</small>
                    </a>
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="pool-box-list">
                    <a href="">
                      <i>
                        <img
                          data-src="images/pool-icon-home/web-icon.svg"
                          src="https://e7.pngegg.com/pngimages/474/219/png-clipart-tally-solutions-enterprise-resource-planning-tally-erp9-accounting-software-management-business-text-people-thumbnail.png"
                          data-lazy=""
                          alt="Web tech link icon"
                          width="35"
                          height="35"
                          data-processed="true"
                        />
                      </i>
                      <small>Tally</small>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* Erp Tab */}
        <div className="tab-content case-studio-tab-content">
          <div
            class="tab-pane"
            id="paymentGateway"
            role="tabpanel"
          >
            <div class="services-list-2">
              <div className="row">
              {PaymentGatewayServices.map((service,index)=>(

                <div className="col-md-3" key={index}>
                  <div class="pool-box-list">
                    <a href="">
                      <i>
                        <img
                          data-src="images/pool-icon-home/web-icon.svg"
                          src={service.logo}
                          data-lazy=""
                          alt="Web tech link icon"
                          width="45"
                          height="35"
                          data-processed="true"
                        />
                      </i>
                      <small>{service.name}</small>
                    </a>
                  </div>
                </div>
              ))}
                {/* <div className="col-md-3">
                  <div class="pool-box-list">
                    <a href="">
                      <i>
                        <img
                          data-src="images/pool-icon-home/web-icon.svg"
                          src="https://e7.pngegg.com/pngimages/474/219/png-clipart-tally-solutions-enterprise-resource-planning-tally-erp9-accounting-software-management-business-text-people-thumbnail.png"
                          data-lazy=""
                          alt="Web tech link icon"
                          width="35"
                          height="35"
                          data-processed="true"
                        />
                      </i>
                      <small>Tally</small>
                    </a>
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="pool-box-list">
                    <a href="">
                      <i>
                        <img
                          data-src="images/pool-icon-home/web-icon.svg"
                          src="https://e7.pngegg.com/pngimages/474/219/png-clipart-tally-solutions-enterprise-resource-planning-tally-erp9-accounting-software-management-business-text-people-thumbnail.png"
                          data-lazy=""
                          alt="Web tech link icon"
                          width="35"
                          height="35"
                          data-processed="true"
                        />
                      </i>
                      <small>Tally</small>
                    </a>
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="pool-box-list">
                    <a href="">
                      <i>
                        <img
                          data-src="images/pool-icon-home/web-icon.svg"
                          src="https://e7.pngegg.com/pngimages/474/219/png-clipart-tally-solutions-enterprise-resource-planning-tally-erp9-accounting-software-management-business-text-people-thumbnail.png"
                          data-lazy=""
                          alt="Web tech link icon"
                          width="35"
                          height="35"
                          data-processed="true"
                        />
                      </i>
                      <small>Tally</small>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
