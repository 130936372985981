import React from "react";
import "./About.css";
import playBtn from "../../images/play-btn-icon.png";

const About = () => {
  return (
    <div id="about" className="about container-fluid">
      <div className="container">
        <div className="row align-items-center">
          <div
            className="heading col-12 col-sm-12 col-md-12 col-lg-6"
            data-aos="fade-down-right"
          >
            <h6>About Us</h6>
            <h4>
              We're <span>DEEPECOM</span>!
            </h4>
            <h5>
              We’re so excited to have you here and hope you’ll join us on our
              journey.
            </h5>
            <p>
              DeepEcom is a SaaS platform designed for e-commerce sellers to
              automate accounting, payment reconciliation, and tax compliance.
            </p>
            <p>
              <span>
                At Deep EcomInsight, our mission is to drive value for
                businesses by empowering a strategic finance and accounting
                function.
              </span>{" "}
              <br /> We’re doing that by building the tools and resources that
              we wished we had existed. Leveraging the power of technology and
              data science we’re automating accounts receivable reconciliation,
              an extremely manual and repetitive task but one with hidden
              insights if you know where to look.
            </p>
          </div>
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-6"
            data-aos="fade-down-left"
          >
            <div className="about-img-wrap">
              <div className="play-btn-02">
                <a className="popup-video" href="/">
                  <img src={playBtn} className="img-fluid" alt="" />
                </a>
              </div>
              <div className="about-img about-img-big">
                <img
                  className="img-fluid"
                  src="https://thepixelcurve.com/html/techwix/techwix/assets/images/about-big2.jpg"
                  alt=""
                />
              </div>
              <div className="about-img about-img-sm">
                <img
                  className="img-fluid"
                  src="https://thepixelcurve.com/html/techwix/techwix/assets/images/about-sm2.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
