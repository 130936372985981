import React from 'react';
import './ProductCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';

const ProductCard = ({imgSrc, headline,title,description}) => {
  return (
    <div className='product-card' data-aos="flip-up" >
      <div className="product-image">
        <img src={imgSrc} alt="ERP Integration" />
      </div>
      <div className="product-content">
        <h4><FontAwesomeIcon icon={faBolt} className='bolt-icon' />{headline}</h4>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ProductCard;
