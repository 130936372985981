export const BenefitData=[
    {
        count:1,
        title:"Streamlined Operations",
        description:"Say goodbye to disjointed processes. DeepEcom integrates with leading ERP solutions like Tally / Zoho to deliver a unified operation platform. Manage inventory, orders, and finances without switching between tools."
    },
    {
        count:2,
        title:"Accuracy in Finances",
        description:"Eliminate errors and ensure every financial transaction is recorded accurately. Our automated payment and return reconciliation features work tirelessly to ensure that your financial data is meticulous, leading to a trusted financial picture."
    },
    {
        count:3,
        title:"Simplified Tax Compliance",
        description:"With DeepEcom, ease the burden of tax filing. Our platform is designed to make GST returns a one-click operation, ensuring you remain compliant with minimal fuss."
    },
    {
        count:4,
        title:"Time and Cost Efficiency",
        description:"Free up hours of manual work with our automated reconciliation system. DeepEcom dramatically reduces the time spent on reconciling payments and returns, helping you save on operational costs and focus more on your business growth."
    },
    {
        count:5,
        title:"Data-Driven Decisions",
        description:"Unlock the potential of your business data. Utilize our in-depth analytics and business intelligence tools to gain insights into sales trends, customer behavior, and market performance, helping you to make better strategic decisions."
    },
    {
        count:6,
        title:"Enhanced Productivity",
        description:"Boost your team's productivity. DeepEcom's intuitive design and automated workflow minimize the need for manual intervention, allowing your team to concentrate on higher-value tasks."
    }
]
