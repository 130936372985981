 const faqData = [
  {
    ques: "Can DeepEcom integrate with Amazon, Flipkart and other marketplaces?",
    ans: "Absolutely! DeepEcom is designed to work seamlessly with Amazon, Flipkart and a variety of other marketplaces, ensuring that all your ecommerce channels are integrated into one central system for easier management.",
  },
  {
    ques: "Is it possible to integrate multiple warehouses into Tally using DeepEcom?",
    ans: "Yes, our solution supports the integration of multiple warehouses. You can manage multiple inventory locations within Tally, making it effortless to keep tabs on your stock across different warehouses.",
  },
  {
    ques: "My business operates in multiple states with different GST numbers. Can DeepEcom accommodate this?",
    ans: "Definitely! DeepEcom can handle multiple GST registrations, allowing you to manage your materials and finances effectively, even when operating across different states.",
  },
  {
    ques: "Will I need to manually upload files for accounting purposes into the system?",
    ans: "Not at all. Our solution automates the process, eliminating the need for manual file uploads. Your accounting data is updated automatically, ensuring a streamlined process.",
  },
  {
    ques: "Can I sync data from previous fiscal years into Tally with DeepEcom?",
    ans: "Yes, syncing data from previous years into Tally is a feature DeepEcom provides to ensure that your historical data is captured and integrated accurately.",
  },
  {
    ques: "How is pricing determined for DeepEcom?",
    ans: "Our pricing model is based on the volume of orders you process. Please share your email address with us, and we will send you detailed pricing information tailored to your business needs.",
  },
  {
    ques: "How does DeepEcom handle products with different names across Amazon, Flipkart, and Tally?",
    ans: "During the initial configuration stage, we meticulously map your products across different platforms to ensure they are recognized and managed properly within our system.",
  },
  {
    ques: "Does DeepEcom account for all the expenses charged by Amazon and other marketplaces?",
    ans: "Absolutely, our system comprehensively captures and categorizes all marketplace expenses, giving you a complete view of your costs.",
  },
  {
    ques: "Can I reconcile marketplace warehouse inventory with my Tally accounts?",
    ans: "Yes, you can reconcile inventory stored at marketplace warehouses with Tally, ensuring that your inventory records are consistent and up-to-date.",
  },
  {
    ques: "Is it possible to analyze profit and loss within DeepEcom?",
    ans: "Certainly! DeepEcom provides the capability to determine profit and loss, helping you make informed decisions about your products and pricing strategies.",
  },
];

export default faqData