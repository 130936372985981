import React from "react";
import FaqItem from "./FaqItem";
import faqData from "./FaqData";

const Faq = () => {
  return (
    <div id="faq" className="faq container-fluid">
      <div className="container">
        <div className="row">
          <div className="heading col-12">
            <h1>Frequently Asked Questions</h1>
            <p>
              Do you need some help with something or do you have questions on
              some features?
            </p>
            <div className="sep-element primary" />
          </div>
          <div
            className="faq-container col-12 col-sm-12 col-md-12 col-lg-8 mx-auto"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            {faqData.map((faq, index) => (
              <FaqItem key={index} question={faq.ques} answer={faq.ans} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
