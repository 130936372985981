import React, { useEffect, useState } from "react";
import "./ProductsPage.css";
import Product1Img from "../../images/dash.png";
import TallyImg from "../../images/tally.png";
import AutomatedImg from "../../images/automated.jpg";
import GSTImg from "../../images/gst.png";
import Benefit1Img from "../../images/bene.png";
import MultiImg from "../../images/multi.png";
import HowImg from "../../images/how.png";
import AIImg from "../../images/ai.png";
import Footer from "../Footer/Footer";
import "../Products/Products.css";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const ProductsPage = () => {
  const [data, setData] = useState(0);
  const param = useParams();
  const feature = [
    {
      id: "RealProfitTracking",
      img:Product1Img,
      title: "Real Profit Tracking",
      sub: "Get accurate earnings after fees & refunds.",
      description:
        "Many e-commerce sellers struggle to understand their actual earnings due to multiple deductions like commissions, refunds, penalties, and logistics charges. Real Profit Tracking gives sellers a clear and accurate picture of their true profits after all platform deductions.",
      keyBenefits: [
        "Eliminates Guesswork – Know exactly how much you're earning after all fees and refunds.",
        "Better Decision-Making – Get insights into profitable products and loss-making SKUs.",
        "Optimized Pricing Strategy – Adjust pricing based on actual profit margins.",
      ],
      howAchieveThis: [
        "Automated Calculation: We fetch your sales data and apply marketplace-specific charges (Amazon, Flipkart, etc.) to determine your net earnings.",
        "Fee Breakdown: We categorize platform fees, refunds, and shipping costs for clear visibility.",
        "Real-Time Updates: Track profits daily, weekly, or monthly to monitor trends.",
      ],
      example:
        "A seller earning ₹1,00,000 in sales might only take home ₹78,000 after deductions. DeepEcom provides a detailed breakdown of all expenses, helping sellers optimize pricing and expenses.",
    },

    {
      id: "TallyIntegration",
      img:TallyImg,
      title: "Tally Integration",
      sub: "Auto-sync sales & expenses, no manual work.",
      description:
        "Tally is the backbone of accounting for many businesses. However, manually entering sales data into Tally is time-consuming and error-prone. DeepEcom automates the entire process, eliminating manual work.",
      keyBenefits: [
        "100% Accurate Accounting – No more manual data entry errors.",
        "Saves Hours of Work – Sync sales, refunds, and expenses automatically.",
        "Compliant with GST & Taxation – Ensures proper bookkeeping for GST filing.",
      ],
      howAchieveThis: [
        "Auto-Sync Sales & Expenses: Orders, payments, and refunds sync automatically with Tally.",
        "Tally-Compatible Vouchers: Generates sales invoices, payment receipts, and expense entries.",
        "Multi-Branch Support: If you operate multiple stores, sync data seamlessly across them.",
      ],
      example:
        "A Flipkart seller handling 500+ orders daily manually enters sales data into Tally. With DeepEcom, the entire process is automated, saving 10+ hours per week!",
    },
    {
      id: "AutomatedPayment",
      title: "Automated Payment Reconciliation",
      img:AutomatedImg,
      sub: "Match settlements, refunds & deductions.",
      description:
        "Marketplace payments are complex, with multiple deductions like commissions, refunds, and penalties. Many sellers struggle to match actual payments received vs. expected payments.",
      keyBenefits: [
        "Detect Missing Payments – Ensure you’re paid the right amount.",
        "Resolve Discrepancies – Identify overcharges and claim refunds.",
        "Cash Flow Clarity – Know exactly when and how much you’re getting paid.",
      ],
      howAchieveThis: [
        "Auto-Matching System: Matches sales with payments from Amazon, Flipkart, etc.",
        "Discrepancy Alerts: Notifies you of any missing or incorrect payments.",
        "Refund & Chargeback Tracking: Monitors refunds and chargebacks for better accounting.",
      ],
      example:
        "A seller expecting ₹5,00,000 in payouts from Amazon notices a ₹20,000 shortfall due to hidden charges. DeepEcom highlights the issue and helps the seller claim a refund.",
    },

    {
      id: "GstTax",
      title: "GST & Tax Compliance",
      img:GSTImg,
      sub: "Auto GST, TDS, and TCS calculations.",
      description:
        "E-commerce taxation is complicated due to TDS, TCS, and GST on different transactions. Failing to comply can result in penalties, interest charges, and legal issues.",
      keyBenefits: [
        "Auto-Generated GST Reports – Hassle-free GST filing.",
        "Prevents Overpayment – Claim accurate Input Tax Credit (ITC).",
        "Avoids Compliance Issues – Ensures smooth tax audits.",
      ],
      howAchieveThis: [
        "Automated GST Calculations: Computes GST, TCS, and TDS automatically.",
        "Filing-Ready Reports: Generates tax reports ready for upload to the GST portal.",
        "GST Mismatch Detection: Detects any tax discrepancies in marketplace statements.",
      ],
      example:
        "A seller paying ₹50,000 in GST monthly realizes through DeepEcom that ₹8,000 was overpaid due to incorrect TCS deductions. The system helps them reclaim the excess amount.",
    },

    {
      id: "MultiMarketPlace",
      title: "Multi-Marketplace Support",
      img:MultiImg,
      sub: "Works with Amazon, Flipkart, Myntra, Meesho & more.",
      description:
        "Many sellers operate on multiple platforms (Amazon, Flipkart, Myntra, Meesho, etc.), but managing financials across different dashboards is a nightmare. DeepEcom centralizes everything in one place.",
     keyBenefits: [
        "All Sales in One Dashboard – No need to log into multiple portals.",
        "Unified Reports – Compare performance across marketplaces.",
        "Easier Accounting – Single system for Tally integration & tax compliance.",
      ],
      howAchieveThis: [
        "Marketplace API Integration: Connects directly with Amazon, Flipkart, Myntra, Meesho, etc.",
        "Automated Data Consolidation: Aggregates sales, refunds, and settlements from all platforms.",
        "Cross-Platform Insights: Provides performance comparison across marketplaces.",
      ],
      example:
        "A seller managing Flipkart and Amazon orders saves 5+ hours per week by viewing all reports in DeepEcom instead of logging into separate seller dashboards.",
    },
    {
      id: "AiPowered",
      img:AIImg,
      title: "AI-powered Insights",
      sub: "Data-driven decisions for business growth.",
      description:
        "Understanding sales trends, refund patterns, and fee structures manually is difficult. DeepEcom’s AI-driven analytics provide actionable insights to optimize operations.",
        keyBenefits: [
          "Detect Overcharges – Identify unnecessary fees or commissions.",
          "Optimize Inventory – Know which products are selling best and restock accordingly.",
          "Improve Profit Margins – AI suggests ways to cut costs & increase earnings.",
        ],
        howAchieveThis: [
          "Smart Analytics Engine: Analyzes data to uncover patterns and anomalies.",
          "Dynamic Profit Forecasting: Predicts future sales & cash flow trends.",
          "Customized Alerts & Suggestions: Recommends pricing and restocking strategies.",
        ],
        example:
          "A seller notices a sudden increase in refund rates for a product. DeepEcom’s AI analyzes customer feedback & identifies a product defect, helping them fix the issue before more losses occur.",
     },
  ];

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
    if (param && param.id) {
      const response = feature.filter((item) => item.id === param.id).flat(1);
      setData(response[0]);
    }
    // eslint-disable-next-line
  }, [param]);

  return (
    <>
      <section className="productsPage">
        <div
          id="products"
          className="products container-fluid"
          style={{
            background: "white",
          }}
        >
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 mx-auto text-center product-heading">
              <h5>Our Products</h5>
              <div className="sep-element primary" />
            </div>
          </div>
        </div>
        <div>
          <div
            className="row"
            style={{
              marginBottom: "50px",
            }}
          >
            <div className="col-md-6 image-part">
              <div className="image-container">
                <img src={data.img} alt="Real Profile Tracking Img" />
              </div>
            </div>
            <div className="col-md-6">
              <h1>{data.title}</h1>
              <p>{data.description}</p>
              <h4>{data.sub}</h4>
            </div>
          </div>
        </div>
      </section>

      <section
        id="about"
        className="section keyFeatures bg-inherit overflow-hidden ptb_100"
      >
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-lg-6 order-1 order-lg-1 d-none d-md-block text-start">
              <div
                className="service-thumb mx-auto aos-init aos-animate"
                data-aos="fade-left"
              >
                <img className="img-fluid" src={Benefit1Img} alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-4 mt-lg-0 order-2 order-lg-2">
              <div className="keyFetaure-points-text pt-lg-0 px-0 px-lg-4">
                <h1
                  className="text-capitalize mb-lg-4 mb-2 aos-init aos-animate"
                  data-aos="fade-right"
                >
                  🎯 Key Benefits:
                </h1>
                <ul className="keyFeature-list style-two">
                  {data?.keyBenefits?.map((item) => {
                    return (
                      <li className="keyFetaure-points py-2">
                        <div
                          className="keyFetaure-points-text aos-init"
                          data-aos="fade-right"
                        >
                          <p>{item}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="about"
        className="section keyFeatures bg-inherit overflow-hidden ptb_100"
      >
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-lg-6 mt-4 mt-lg-0 order-1 order-lg-1">
              <div className="keyFetaure-points-text pt-lg-0 px-0 px-lg-4">
                <h1
                  className="text-capitalize mb-lg-4 mb-2 aos-init aos-animate"
                  data-aos="fade-right"
                >
                  🔍 How DeepEcom Achieves This?
                </h1>

                <ul className="keyFeature-list style-two">
                  {data?.howAchieveThis?.map((item) => {
                    return (
                      <li className="keyFetaure-points py-2">
                        <div
                          className="keyFetaure-points-text aos-init"
                          data-aos="fade-right"
                        >
                          <p>{item}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-6 order-2 order-lg-2 d-none d-md-block text-start">
              <div
                className="service-thumb mx-auto aos-init aos-animate"
                data-aos="fade-left"
              >
                <img className="img-fluid" src={HowImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="exampleSection">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 text-center">
              <h1
                className="text-capitalize mb-lg-4 mb-2 aos-init aos-animate"
                data-aos="fade-down"
              >
                📌 Example Use Case:
              </h1>
              <p className="aos-init aos-animate" data-aos="fade-left">
                {data.example}
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ProductsPage;
