import React, { useEffect, useState } from "react";
import "./Pricing.css";
import Footer from "../Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { DemoModal } from "../InfoModal";

const PricingPage = () => {
  const pricingData = [
    {
      planName: "Beginner Seller",
      pricing: {
        quarterly: {
          totalPrice: 3000,
          discount: 0,
          pricePerMonth: 1000,
          billingCycle: "3 Months",
        },
        halfYearly: {
          totalPrice: 6000,
          discount: 5,
          discountedPrice: 5700,
          pricePerMonth: 950,
          billingCycle: "6 Months",
        },
        yearly: {
          totalPrice: 12000,
          discount: 10,
          discountedPrice: 10800,
          pricePerMonth: 900,
          billingCycle: "12 Months",
        },
      },
      features: [
        "Upto 500 Orders/Month",
        "2 Marketplace",
        "1 GST No.",
        "Tally Sync",
        "Reports",
      ],
    },
    {
      planName: "Emerging Seller",
      pricing: {
        quarterly: {
          totalPrice: 6000,
          discount: 0,
          pricePerMonth: 2000,
          billingCycle: "3 Months",
        },
        halfYearly: {
          totalPrice: 12000,
          discount: 5,
          discountedPrice: 11400,
          pricePerMonth: 1900,
          billingCycle: "6 Months",
        },
        yearly: {
          totalPrice: 24000,
          discount: 10,
          discountedPrice: 21600,
          pricePerMonth: 1800,
          billingCycle: "12 Months",
        },
      },
      features: [
        "Upto 1000 Orders/Month",
        "4 Marketplace",
        "2 or more GST No.",
        "Tally Sync",
        "Reports",
        "Data Insights",
        "Profit Tracking",
      ],
    },
    {
      planName: "Scaling Seller",
      pricing: {
        quarterly: {
          totalPrice: 9000,
          discount: 0,
          pricePerMonth: 3000,
          billingCycle: "3 Months",
        },
        halfYearly: {
          totalPrice: 18000,
          discount: 5,
          discountedPrice: 17100,
          pricePerMonth: 2850,
          billingCycle: "6 Months",
        },
        yearly: {
          totalPrice: 36000,
          discount: 10,
          discountedPrice: 32400,
          pricePerMonth: 2700,
          billingCycle: "12 Months",
        },
      },
      features: [
        "Upto 2000 Orders/Month",
        "4 Marketplace",
        "2 or more GST No.",
        "Tally Sync",
        "Reports",
        "Data Insights",
        "Profit Tracking",
      ],
    },
    {
      planName: "Established Seller",
      pricing: {
        quarterly: {
          totalPrice: 12000,
          discount: 0,
          pricePerMonth: 4000,
          billingCycle: "3 Months",
        },
        halfYearly: {
          totalPrice: 24000,
          discount: 5,
          discountedPrice: 22800,
          pricePerMonth: 3800,
          billingCycle: "6 Months",
        },
        yearly: {
          totalPrice: 48000,
          discount: 10,
          discountedPrice: 43200,
          pricePerMonth: 3600,
          billingCycle: "12 Months",
        },
      },
      features: [
        "Upto 4000 Orders/Month",
        "4 Marketplace",
        "2 or more GST No.",
        "Tally Sync",
        "Reports",
        "Data Insights",
        "Profit Tracking",
      ],
    },
    {
      planName: "High-Volume Seller",
      pricing: {
        quarterly: {
          totalPrice: 15000,
          discount: 0,
          pricePerMonth: 5000,
          billingCycle: "3 Months",
        },
        halfYearly: {
          totalPrice: 30000,
          discount: 5,
          discountedPrice: 28500,
          pricePerMonth: 4750,
          billingCycle: "6 Months",
        },
        yearly: {
          totalPrice: 60000,
          discount: 10,
          discountedPrice: 54000,
          pricePerMonth: 4500,
          billingCycle: "12 Months",
        },
      },
      features: [
        "Upto 6000 Orders/Month",
        "4 Marketplace",
        "2 or more GST No.",
        "Tally Sync",
        "Reports",
        "Data Insights",
        "Profit Tracking",
      ],
    },
    {
      planName: "Power Seller",
      pricing: {
        quarterly: {
          totalPrice: 18000,
          discount: 0,
          pricePerMonth: 6000,
          billingCycle: "3 Months",
        },
        halfYearly: {
          totalPrice: 36000,
          discount: 5,
          discountedPrice: 34200,
          pricePerMonth: 5700,
          billingCycle: "6 Months",
        },
        yearly: {
          totalPrice: 72000,
          discount: 10,
          discountedPrice: 64800,
          pricePerMonth: 5400,
          billingCycle: "12 Months",
        },
      },
      features: [
        "Upto 8000 Orders/Month",
        "4 Marketplace",
        "2 or more GST No.",
        "Tally Sync",
        "Reports",
        "Data Insights",
        "Profit Tracking",
      ],
    },
  ];

  const navigate = useNavigate();

  const waitForElement = (id, retries = 10, delay = 300) => {
    return new Promise((resolve, reject) => {
      let attempts = 0;

      const interval = setInterval(() => {
        const element = document.getElementById(id);
        if (element) {
          clearInterval(interval);
          resolve(element);
        }
        attempts += 1;
        if (attempts >= retries) {
          clearInterval(interval);
          reject("Element not found");
        }
      }, delay);
    });
  };

  const handleScroll = async (e, id) => {
    e.preventDefault();
    if (window.location.pathname !== "/") {
      navigate("/");
    }

    try {
      const element = await waitForElement(id);
      console.log(element, "element");

      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } catch (error) {
      console.error(error);
    }
  };
  const [planDetails, setPlanDetails] = useState(pricingData[0]);

  const [selectedPlans, setSelectedPlans] = useState(
    pricingData.map((plan) => ({
      planName: plan.planName,
      selectedBillingCycle: "quarterly",
    }))
  );

  const updatePlanDetails = (planName, billingCycle) => {
    setSelectedPlans((prevPlans) =>
      prevPlans.map((p) =>
        p.planName === planName
          ? { ...p, selectedBillingCycle: billingCycle }
          : p
      )
    );
  };

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div id="pricing" className="pricing" style={{ marginTop: "100px" }}>
        <div className="container">
          <div className="heading col-12">
            <h5>Pricing</h5>
            <h6>Flexible Plans Tailored to Your Needs</h6>
            <ul className="list-inline">
              <li className="list-inline-item">Pay As You Go</li>
              {/* <li className="list-inline-item">|</li>
                <li className="list-inline-item">
                  Pricing model: Starting at INR 500 per month
                </li> */}
            </ul>
            <div className="sep-element primary" />
          </div>

          <div className="row">
            {pricingData.map((plan) => {
              const selectedPlan = selectedPlans.find(
                (p) => p.planName === plan.planName
              );
              const selectedBillingCycle = selectedPlan
                ? selectedPlan.selectedBillingCycle
                : "quarterly";
              const planDetails = plan.pricing[selectedBillingCycle];

              return (
                <div className="col-md-4" key={plan.planName}>
                  <div className="pricing-box popular">
                    <h3>{plan.planName}</h3>
                    <div className="price font-title">
                      <span className="num">
                        <span className="symbol">₹</span>
                        {planDetails.pricePerMonth}
                      </span>
                      <span className="mon">/month</span>
                    </div>
                    <p className="desc">
                      Billed ₹
                      {planDetails.discountedPrice || planDetails.totalPrice}/
                      {planDetails.billingCycle}
                    </p>
                    <div className="pricing-select">
                      <select
                        className="form-control"
                        value={selectedBillingCycle}
                        onChange={(e) =>
                          updatePlanDetails(plan.planName, e.target.value)
                        }
                      >
                        <option value="quarterly">Quarterly Plan</option>
                        <option value="halfYearly">Half-Yearly Plan</option>
                        <option value="yearly">Yearly Plan</option>
                      </select>
                    </div>
                    <div onClick={() => setShowModal(true)} className="buy-btn">
                      <span>Buy Now</span>
                    </div>
                    <div className="divider"></div>
                    <ul className="rights-list expanded">
                      {plan.features.map((feature, index) => (
                        <li className="right-item" key={index}>
                          <img
                            src="https://website-prod.cache.wpscdn.com/img/check-dark.6434eff.svg"
                            alt="check"
                          />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
          <DemoModal
            showModal={showModal}
            heading="Buy Now"
            onClose={() => setShowModal(false)}
          />
        </div>
        <div
          className="col-md-12"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 0px",
          }}
        >
          <div className="col-md-4" key={1}>
            <div className="pricing-box">
              <h3>Enterprise Seller</h3>
              <p style={{
                textAlign:'center',
                margin:'20px 0px'
              }}>Above 8000 Orders/Month</p>
              <div onClick={() => setShowModal(true)} className="buy-btn">
                <span>Let's Discuss</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default PricingPage;
