import "./App.css";
import LandingPage from "./components/LandingPage/LandingPage";
import Navbar from "./components/Navbar/Navbar";
import Products from "./components/Products/Products";
import Pricing from "./components/Pricing/Pricing";
import Integration from "./components/IntegrationSection/Integration";
import Benefits from "./components/Benefits/Benefits";
import Testimonials from "./components/Testimonials/Testimonials";
import Faq from "./components/FAQ/Faq";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import "./responsive.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProductsPage from "./components/ProductsPage/ProductsPage";
import PricingPage from "./components/PricingPage/Pricing";
import Clients from "./components/Client/Client";
import Privacy from "./components/Privacy";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <LandingPage />
                <About />
                <Products />
                <Pricing />
                <Integration />
                <Benefits />
                <Testimonials />
                <Clients />
                <Faq />
                <Contact />
                <Footer />
              </>
            }
          />
          <Route path="/products/:id" element={<ProductsPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/privacy-policy" element={<Privacy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
